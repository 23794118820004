export const limitSheetAndCRM = {
    default: {
        companies: 10000,
        decisors: 10000,
    },
    agendor: {
        companies: 1500,
        decisors: 1500,
    },
    excel: {
        companies: 100000,
        decisors: 100000,
    },
};
export function exceedLimit(tab, companies, decisors, withDecisors = true) {
    if (tab == 'csv')
        return false;
    const result = limitSheetAndCRM[tab] || limitSheetAndCRM['default'];
    return (companies > result.companies || (decisors > result.decisors && withDecisors));
}
export function exceedLimitCompanies(tab, companies) {
    if (tab == 'csv')
        return false;
    const result = limitSheetAndCRM[tab] || limitSheetAndCRM['default'];
    return companies > result.companies;
}
export function exceedLimitDecisors(tab, decisors) {
    if (tab == 'csv')
        return false;
    const result = limitSheetAndCRM[tab] || limitSheetAndCRM['default'];
    return decisors > result.decisors;
}
export function handleSelectedService(tab) {
    switch (tab) {
        case 'excel':
            return 'excel-companies';
        case 'csv':
            return 'csv-companies';
    }
    return tab;
}
export const infoSVG = `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.78467 13H7.61035V6.13184H8.78467V13ZM7.51514 4.31006C7.51514 4.11963 7.57227 3.95882 7.68652 3.82764C7.80501 3.69645 7.97852 3.63086 8.20703 3.63086C8.43555 3.63086 8.60905 3.69645 8.72754 3.82764C8.84603 3.95882 8.90527 4.11963 8.90527 4.31006C8.90527 4.50049 8.84603 4.65918 8.72754 4.78613C8.60905 4.91309 8.43555 4.97656 8.20703 4.97656C7.97852 4.97656 7.80501 4.91309 7.68652 4.78613C7.57227 4.65918 7.51514 4.50049 7.51514 4.31006Z" fill="#5F6A6C"/>
<path d="M15.9996 8.62988C15.9996 10.1132 15.56 11.5633 14.7359 12.7967C13.9118 14.03 12.7402 14.9913 11.3697 15.559C9.99926 16.1266 8.49156 16.2752 7.0367 15.9858C5.58184 15.6964 4.24575 14.9821 3.19686 13.9332C2.14796 12.8843 1.43351 11.5479 1.14412 10.0931C0.854734 8.63821 1.00322 7.13021 1.57088 5.75977C2.13854 4.38932 3.10021 3.21797 4.33358 2.39386C5.56694 1.56975 7.01623 1.12988 8.49959 1.12988C10.4887 1.12988 12.3968 1.92006 13.8033 3.32658C15.2098 4.73311 15.9996 6.64076 15.9996 8.62988Z" stroke="#5F6A6C" stroke-miterlimit="10"/>
</svg>
`;
export const decisorsLevelIcons = {
    Todos: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1651_869" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="10" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 10)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1651_869)">
    <path d="M6.83491 2.02375C6.74676 2.05099 6.63309 2.12477 6.5751 2.19287C6.55075 2.22125 5.91748 3.28028 5.1659 4.54816C1.47067 10.7877 0.0823483 13.1328 0.0672704 13.1634C0.041754 13.2122 0 13.3666 0 13.4109C0.00115983 13.4665 0.0510327 13.6265 0.0869876 13.6878C0.135701 13.7684 0.235446 13.866 0.311995 13.9057C0.424499 13.9636 0.48829 13.9739 0.787528 13.9818C1.26074 13.992 13.4204 14.0068 13.4982 13.9966C13.5863 13.9841 13.6687 13.9511 13.7568 13.8944C13.8438 13.8376 13.9006 13.7718 13.9505 13.6708C14.0189 13.53 14.0166 13.3382 13.9424 13.185C13.9006 13.0987 7.94719 2.97154 7.69202 2.55269C7.58648 2.37903 7.47977 2.21444 7.45542 2.1872C7.39395 2.11909 7.28492 2.04985 7.19445 2.02261C7.09355 1.99196 6.93465 1.9931 6.83491 2.02375Z" fill="#95A5A6"/>
    </g>
    <mask id="mask1_1651_869" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 5)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask1_1651_869)">
    <path d="M6.83491 1.02375C6.74676 1.05099 6.63309 1.12477 6.5751 1.19287C6.55075 1.22125 5.91748 2.28028 5.1659 3.54816C1.47067 9.78769 0.0823483 12.1328 0.0672704 12.1634C0.041754 12.2122 0 12.3666 0 12.4109C0.00115983 12.4665 0.0510327 12.6265 0.0869876 12.6878C0.135701 12.7684 0.235446 12.866 0.311995 12.9057C0.424499 12.9636 0.48829 12.9739 0.787528 12.9818C1.26074 12.992 13.4204 13.0068 13.4982 12.9966C13.5863 12.9841 13.6687 12.9511 13.7568 12.8944C13.8438 12.8376 13.9006 12.7718 13.9505 12.6708C14.0189 12.53 14.0166 12.3382 13.9424 12.185C13.9006 12.0987 7.94719 1.97154 7.69202 1.55269C7.58648 1.37903 7.47977 1.21444 7.45542 1.1872C7.39395 1.11909 7.28492 1.04985 7.19445 1.02261C7.09355 0.991964 6.93465 0.993099 6.83491 1.02375Z" fill="#95A5A6"/>
    </g>
    <mask id="mask2_1651_869" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 0)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask2_1651_869)">
    <path d="M6.83491 0.0237461C6.74676 0.0509879 6.63309 0.124768 6.5751 0.192873C6.55075 0.22125 5.91748 1.28028 5.1659 2.54816C1.47067 8.78769 0.0823483 11.1328 0.0672704 11.1634C0.041754 11.2122 0 11.3666 0 11.4109C0.00115983 11.4665 0.0510327 11.6265 0.0869876 11.6878C0.135701 11.7684 0.235446 11.866 0.311995 11.9057C0.424499 11.9636 0.48829 11.9739 0.787528 11.9818C1.26074 11.992 13.4204 12.0068 13.4982 11.9966C13.5863 11.9841 13.6687 11.9511 13.7568 11.8944C13.8438 11.8376 13.9006 11.7718 13.9505 11.6708C14.0189 11.53 14.0166 11.3382 13.9424 11.185C13.9006 11.0987 7.94719 0.971536 7.69202 0.552693C7.58648 0.379026 7.47977 0.214439 7.45542 0.187197C7.39395 0.119093 7.28492 0.0498529 7.19445 0.022611C7.09355 -0.00803613 6.93465 -0.00690105 6.83491 0.0237461Z" fill="#95A5A6"/>
    </g>
    </svg>
    `,
    'Não classificado': `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1651_921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="10" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 10)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1651_921)">
    <path d="M6.83491 2.02375C6.74676 2.05099 6.63309 2.12477 6.5751 2.19287C6.55075 2.22125 5.91748 3.28028 5.1659 4.54816C1.47067 10.7877 0.0823483 13.1328 0.0672704 13.1634C0.041754 13.2122 0 13.3666 0 13.4109C0.00115983 13.4665 0.0510327 13.6265 0.0869876 13.6878C0.135701 13.7684 0.235446 13.866 0.311995 13.9057C0.424499 13.9636 0.48829 13.9739 0.787528 13.9818C1.26074 13.992 13.4204 14.0068 13.4982 13.9966C13.5863 13.9841 13.6687 13.9511 13.7568 13.8944C13.8438 13.8376 13.9006 13.7718 13.9505 13.6708C14.0189 13.53 14.0166 13.3382 13.9424 13.185C13.9006 13.0987 7.94719 2.97154 7.69202 2.55269C7.58648 2.37903 7.47977 2.21444 7.45542 2.1872C7.39395 2.11909 7.28492 2.04985 7.19445 2.02261C7.09355 1.99196 6.93465 1.9931 6.83491 2.02375Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    <mask id="mask1_1651_921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 5)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask1_1651_921)">
    <path d="M6.83491 1.02375C6.74676 1.05099 6.63309 1.12477 6.5751 1.19287C6.55075 1.22125 5.91748 2.28028 5.1659 3.54816C1.47067 9.78769 0.0823483 12.1328 0.0672704 12.1634C0.041754 12.2122 0 12.3666 0 12.4109C0.00115983 12.4665 0.0510327 12.6265 0.0869876 12.6878C0.135701 12.7684 0.235446 12.866 0.311995 12.9057C0.424499 12.9636 0.48829 12.9739 0.787528 12.9818C1.26074 12.992 13.4204 13.0068 13.4982 12.9966C13.5863 12.9841 13.6687 12.9511 13.7568 12.8944C13.8438 12.8376 13.9006 12.7718 13.9505 12.6708C14.0189 12.53 14.0166 12.3382 13.9424 12.185C13.9006 12.0987 7.94719 1.97154 7.69202 1.55269C7.58648 1.37903 7.47977 1.21444 7.45542 1.1872C7.39395 1.11909 7.28492 1.04985 7.19445 1.02261C7.09355 0.991964 6.93465 0.993099 6.83491 1.02375Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    <mask id="mask2_1651_921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 0)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask2_1651_921)">
    <path d="M6.83491 0.0237461C6.74676 0.0509879 6.63309 0.124768 6.5751 0.192873C6.55075 0.22125 5.91748 1.28028 5.1659 2.54816C1.47067 8.78769 0.0823483 11.1328 0.0672704 11.1634C0.041754 11.2122 0 11.3666 0 11.4109C0.00115983 11.4665 0.0510327 11.6265 0.0869876 11.6878C0.135701 11.7684 0.235446 11.866 0.311995 11.9057C0.424499 11.9636 0.48829 11.9739 0.787528 11.9818C1.26074 11.992 13.4204 12.0068 13.4982 11.9966C13.5863 11.9841 13.6687 11.9511 13.7568 11.8944C13.8438 11.8376 13.9006 11.7718 13.9505 11.6708C14.0189 11.53 14.0166 11.3382 13.9424 11.185C13.9006 11.0987 7.94719 0.971536 7.69202 0.552693C7.58648 0.379026 7.47977 0.214439 7.45542 0.187197C7.39395 0.119093 7.28492 0.0498529 7.19445 0.022611C7.09355 -0.00803613 6.93465 -0.00690105 6.83491 0.0237461Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    </svg>
    `,
    Alto: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1651_895" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="10" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 10)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1651_895)">
    <path d="M6.83491 2.02375C6.74676 2.05099 6.63309 2.12477 6.5751 2.19287C6.55075 2.22125 5.91748 3.28028 5.1659 4.54816C1.47067 10.7877 0.0823483 13.1328 0.0672704 13.1634C0.041754 13.2122 0 13.3666 0 13.4109C0.00115983 13.4665 0.0510327 13.6265 0.0869876 13.6878C0.135701 13.7684 0.235446 13.866 0.311995 13.9057C0.424499 13.9636 0.48829 13.9739 0.787528 13.9818C1.26074 13.992 13.4204 14.0068 13.4982 13.9966C13.5863 13.9841 13.6687 13.9511 13.7568 13.8944C13.8438 13.8376 13.9006 13.7718 13.9505 13.6708C14.0189 13.53 14.0166 13.3382 13.9424 13.185C13.9006 13.0987 7.94719 2.97154 7.69202 2.55269C7.58648 2.37903 7.47977 2.21444 7.45542 2.1872C7.39395 2.11909 7.28492 2.04985 7.19445 2.02261C7.09355 1.99196 6.93465 1.9931 6.83491 2.02375Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    <mask id="mask1_1651_895" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 5)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask1_1651_895)">
    <path d="M6.83491 1.02375C6.74676 1.05099 6.63309 1.12477 6.5751 1.19287C6.55075 1.22125 5.91748 2.28028 5.1659 3.54816C1.47067 9.78769 0.0823483 12.1328 0.0672704 12.1634C0.041754 12.2122 0 12.3666 0 12.4109C0.00115983 12.4665 0.0510327 12.6265 0.0869876 12.6878C0.135701 12.7684 0.235446 12.866 0.311995 12.9057C0.424499 12.9636 0.48829 12.9739 0.787528 12.9818C1.26074 12.992 13.4204 13.0068 13.4982 12.9966C13.5863 12.9841 13.6687 12.9511 13.7568 12.8944C13.8438 12.8376 13.9006 12.7718 13.9505 12.6708C14.0189 12.53 14.0166 12.3382 13.9424 12.185C13.9006 12.0987 7.94719 1.97154 7.69202 1.55269C7.58648 1.37903 7.47977 1.21444 7.45542 1.1872C7.39395 1.11909 7.28492 1.04985 7.19445 1.02261C7.09355 0.991964 6.93465 0.993099 6.83491 1.02375Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    <mask id="mask2_1651_895" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 0)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask2_1651_895)">
    <path d="M6.83491 0.0237461C6.74676 0.0509879 6.63309 0.124768 6.5751 0.192873C6.55075 0.22125 5.91748 1.28028 5.1659 2.54816C1.47067 8.78769 0.0823483 11.1328 0.0672704 11.1634C0.041754 11.2122 0 11.3666 0 11.4109C0.00115983 11.4665 0.0510327 11.6265 0.0869876 11.6878C0.135701 11.7684 0.235446 11.866 0.311995 11.9057C0.424499 11.9636 0.48829 11.9739 0.787528 11.9818C1.26074 11.992 13.4204 12.0068 13.4982 11.9966C13.5863 11.9841 13.6687 11.9511 13.7568 11.8944C13.8438 11.8376 13.9006 11.7718 13.9505 11.6708C14.0189 11.53 14.0166 11.3382 13.9424 11.185C13.9006 11.0987 7.94719 0.971536 7.69202 0.552693C7.58648 0.379026 7.47977 0.214439 7.45542 0.187197C7.39395 0.119093 7.28492 0.0498529 7.19445 0.022611C7.09355 -0.00803613 6.93465 -0.00690105 6.83491 0.0237461Z" fill="#95A5A6"/>
    </g>
    </svg>
    `,
    Médio: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1651_843" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="10" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 10)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1651_843)">
    <path d="M6.83491 2.02375C6.74676 2.05099 6.63309 2.12477 6.5751 2.19287C6.55075 2.22125 5.91748 3.28028 5.1659 4.54816C1.47067 10.7877 0.0823483 13.1328 0.0672704 13.1634C0.041754 13.2122 0 13.3666 0 13.4109C0.00115983 13.4665 0.0510327 13.6265 0.0869876 13.6878C0.135701 13.7684 0.235446 13.866 0.311995 13.9057C0.424499 13.9636 0.48829 13.9739 0.787528 13.9818C1.26074 13.992 13.4204 14.0068 13.4982 13.9966C13.5863 13.9841 13.6687 13.9511 13.7568 13.8944C13.8438 13.8376 13.9006 13.7718 13.9505 13.6708C14.0189 13.53 14.0166 13.3382 13.9424 13.185C13.9006 13.0987 7.94719 2.97154 7.69202 2.55269C7.58648 2.37903 7.47977 2.21444 7.45542 2.1872C7.39395 2.11909 7.28492 2.04985 7.19445 2.02261C7.09355 1.99196 6.93465 1.9931 6.83491 2.02375Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    <mask id="mask1_1651_843" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 5)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask1_1651_843)">
    <path d="M6.83491 1.02375C6.74676 1.05099 6.63309 1.12477 6.5751 1.19287C6.55075 1.22125 5.91748 2.28028 5.1659 3.54816C1.47067 9.78769 0.0823483 12.1328 0.0672704 12.1634C0.041754 12.2122 0 12.3666 0 12.4109C0.00115983 12.4665 0.0510327 12.6265 0.0869876 12.6878C0.135701 12.7684 0.235446 12.866 0.311995 12.9057C0.424499 12.9636 0.48829 12.9739 0.787528 12.9818C1.26074 12.992 13.4204 13.0068 13.4982 12.9966C13.5863 12.9841 13.6687 12.9511 13.7568 12.8944C13.8438 12.8376 13.9006 12.7718 13.9505 12.6708C14.0189 12.53 14.0166 12.3382 13.9424 12.185C13.9006 12.0987 7.94719 1.97154 7.69202 1.55269C7.58648 1.37903 7.47977 1.21444 7.45542 1.1872C7.39395 1.11909 7.28492 1.04985 7.19445 1.02261C7.09355 0.991964 6.93465 0.993099 6.83491 1.02375Z" fill="#95A5A6"/>
    </g>
    <mask id="mask2_1651_843" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 0)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask2_1651_843)">
    <path d="M6.83491 0.0237461C6.74676 0.0509879 6.63309 0.124768 6.5751 0.192873C6.55075 0.22125 5.91748 1.28028 5.1659 2.54816C1.47067 8.78769 0.0823483 11.1328 0.0672704 11.1634C0.041754 11.2122 0 11.3666 0 11.4109C0.00115983 11.4665 0.0510327 11.6265 0.0869876 11.6878C0.135701 11.7684 0.235446 11.866 0.311995 11.9057C0.424499 11.9636 0.48829 11.9739 0.787528 11.9818C1.26074 11.992 13.4204 12.0068 13.4982 11.9966C13.5863 11.9841 13.6687 11.9511 13.7568 11.8944C13.8438 11.8376 13.9006 11.7718 13.9505 11.6708C14.0189 11.53 14.0166 11.3382 13.9424 11.185C13.9006 11.0987 7.94719 0.971536 7.69202 0.552693C7.58648 0.379026 7.47977 0.214439 7.45542 0.187197C7.39395 0.119093 7.28492 0.0498529 7.19445 0.022611C7.09355 -0.00803613 6.93465 -0.00690105 6.83491 0.0237461Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    </svg>
    `,
    Baixo: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1651_817" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="10" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 10)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1651_817)">
    <path d="M6.83491 2.02375C6.74676 2.05099 6.63309 2.12477 6.5751 2.19287C6.55075 2.22125 5.91748 3.28028 5.1659 4.54816C1.47067 10.7877 0.0823483 13.1328 0.0672704 13.1634C0.041754 13.2122 0 13.3666 0 13.4109C0.00115983 13.4665 0.0510327 13.6265 0.0869876 13.6878C0.135701 13.7684 0.235446 13.866 0.311995 13.9057C0.424499 13.9636 0.48829 13.9739 0.787528 13.9818C1.26074 13.992 13.4204 14.0068 13.4982 13.9966C13.5863 13.9841 13.6687 13.9511 13.7568 13.8944C13.8438 13.8376 13.9006 13.7718 13.9505 13.6708C14.0189 13.53 14.0166 13.3382 13.9424 13.185C13.9006 13.0987 7.94719 2.97154 7.69202 2.55269C7.58648 2.37903 7.47977 2.21444 7.45542 2.1872C7.39395 2.11909 7.28492 2.04985 7.19445 2.02261C7.09355 1.99196 6.93465 1.9931 6.83491 2.02375Z" fill="#95A5A6"/>
    </g>
    <mask id="mask1_1651_817" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 5)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask1_1651_817)">
    <path d="M6.83491 1.02375C6.74676 1.05099 6.63309 1.12477 6.5751 1.19287C6.55075 1.22125 5.91748 2.28028 5.1659 3.54816C1.47067 9.78769 0.0823483 12.1328 0.0672704 12.1634C0.041754 12.2122 0 12.3666 0 12.4109C0.00115983 12.4665 0.0510327 12.6265 0.0869876 12.6878C0.135701 12.7684 0.235446 12.866 0.311995 12.9057C0.424499 12.9636 0.48829 12.9739 0.787528 12.9818C1.26074 12.992 13.4204 13.0068 13.4982 12.9966C13.5863 12.9841 13.6687 12.9511 13.7568 12.8944C13.8438 12.8376 13.9006 12.7718 13.9505 12.6708C14.0189 12.53 14.0166 12.3382 13.9424 12.185C13.9006 12.0987 7.94719 1.97154 7.69202 1.55269C7.58648 1.37903 7.47977 1.21444 7.45542 1.1872C7.39395 1.11909 7.28492 1.04985 7.19445 1.02261C7.09355 0.991964 6.93465 0.993099 6.83491 1.02375Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    <mask id="mask2_1651_817" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="4">
    <rect width="14" height="4" transform="matrix(-1 0 0 1 14 0)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask2_1651_817)">
    <path d="M6.83491 0.0237461C6.74676 0.0509879 6.63309 0.124768 6.5751 0.192873C6.55075 0.22125 5.91748 1.28028 5.1659 2.54816C1.47067 8.78769 0.0823483 11.1328 0.0672704 11.1634C0.041754 11.2122 0 11.3666 0 11.4109C0.00115983 11.4665 0.0510327 11.6265 0.0869876 11.6878C0.135701 11.7684 0.235446 11.866 0.311995 11.9057C0.424499 11.9636 0.48829 11.9739 0.787528 11.9818C1.26074 11.992 13.4204 12.0068 13.4982 11.9966C13.5863 11.9841 13.6687 11.9511 13.7568 11.8944C13.8438 11.8376 13.9006 11.7718 13.9505 11.6708C14.0189 11.53 14.0166 11.3382 13.9424 11.185C13.9006 11.0987 7.94719 0.971536 7.69202 0.552693C7.58648 0.379026 7.47977 0.214439 7.45542 0.187197C7.39395 0.119093 7.28492 0.0498529 7.19445 0.022611C7.09355 -0.00803613 6.93465 -0.00690105 6.83491 0.0237461Z" fill="#95A5A6" fill-opacity="0.2"/>
    </g>
    </svg>
    `,
};
const fieldFilters = {
    sheet: [
        'Razão Social',
        'CNPJ',
        'Nome Fantasia',
        'Natureza Jurídica',
        'Setor',
        'CNAE Principal',
        'CNAE Secundário',
        'Faixa de Faturamento da Unidade CNPJ',
        'Faixa de Faturamento da Empresa',
        'Faixa de Funcionários da Unidade CNPJ',
        'Faixa de Funcionários da Empresa',
        'Número de Filiais',
        'Data de Abertura',
        'Idade da Empresa',
        'Situação CNPJ',
        'Optante do Simples',
        'Capital Social',
        'Tipo do Logradouro',
        'Logradouro',
        'Número',
        'Bairro',
        'CEP',
        'Complemento',
        'Cidade',
        'UF',
        'País (se localizada no exterior)',
        'Matriz ou filial',
        'Telefones Validados',
        'Telefones de Contador',
        'Telefones de Whatsapp',
        'Todos os telefones extras (exceto de contador)',
        'Horário de validação dos telefones',
        'Endereço na Web',
        'URL do Facebook',
        'URL do Instagram',
        'Administrador',
        'Quadro Societário',
        'Práticas da Empresa',
        // 'Produto ou serviço',
        'Todos os Emails',
        'Emails Válidos',
        'Emails Incertos',
        'Emails de Contador',
        'Nome do tomador de decisão',
        'Posição do tomador de decisão',
        'URL do tomador de decisão',
        'Email do tomador de decisão',
        'Emails Válidos de Decisores',
        'Departamento do tomador de decisão',
        'Nível do tomador de decisão',
        'Histórico: faturamento e funcionários',
        'Hospedagem do website',
        'Anos que importou/exportou',
        'Procon',
        'Reclame Aqui',
        'Dívidas abertas',
        'Processos jurídicos',
        'Uso de tecnologias',
    ],
    CRM: {
        agendor: [
            'Razão Social/Nome Fantasia',
            'Endereço',
            'Situação',
            'CNPJ',
            'Setor',
            'Cnae Primário',
            'Cnae Secundário',
            'Data de Abertura',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Faturamento da Empresa',
            'Faixa de Funcionários da Unidade CNPJ',
            'Faixa de Funcionarios da Empresa',
            'Natureza Jurídica',
            'Administrador',
            'Facebook',
            'Website',
            'Todos os Emails',
            'Todos os Telefones',
            'Telefones Válidos',
            'Decisores',
        ],
        meetime: [
            'Razão Social',
            'CNPJ',
            'Origem',
            'Website',
            'Endereço',
            'Facebook',
            'Decisores',
            'Cnae Primário',
            'Todos os Telefones',
            'Email do tomador de decisão',
            'Cargo do tomador de decisão',
            'LinkedIn do tomador de decisão',
            'Faixa de Faturamento da Empresa',
            'Faixa de Funcionarios da Empresa',
            'Primeiro nome do tomador de decisão',
            'Nome completo do tomador de decisão',
        ],
        ploomes: [
            'Razão Social',
            'CEP',
            'CNPJ',
            'Bairro',
            'CNAE Principal',
            'CNAE Secundário',
            'Todos os Emails',
            'Endereço na Web',
            'Url do Facebook',
            'Rua',
            'Dados dos Decisores',
            'Outras Informações',
            'Decisores',
            'Emails Principais',
            'Telefones Principais',
        ],
        bitrix24: [
            'Razão Social/Nome Fantasia',
            'CNPJ',
            'Setor',
            'Cnae Primário',
            'Cnae Secundário',
            'Data de Abertura',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Faturamento da Empresa',
            'Faixa de Funcionários da Unidade CNPJ',
            'Faixa de Funcionarios da Empresa',
            'Natureza Jurídica',
            'Administrador',
            'Facebook',
            'Website',
            'Todos os Emails',
            'Todos os Telefones',
            'Telefones validos',
            'Decisores',
            'Emails Principais',
            'Telefones Principais',
            'Endereço',
            'Situação',
        ],
        hubspot: [
            'Razão Social',
            'Nome Fantasia',
            'Estado',
            'CNPJ',
            'Cidade',
            'CEP',
            'Endereço',
            'País',
            'Website',
            'Setor',
            'Origem',
            'Data de Abertura',
            'Capital Social',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Funcionários da Unidade CNPJ',
            'Todos os Telefones',
            'Todos os Emails',
            'Emails Válidos',
            'Página da Empresa no Facebook',
            'Outras Informações',
            'Decisores',
            'Telefones Principais',
            'Emails Principais',
        ],
        pipedrive: [
            'Razão Social',
            'Nome Fantasia',
            'CNPJ',
            'Endereço',
            'CNAE Principal',
            'CNAE Secundário',
            'Quadro Societário',
            'Setor',
            'Todos os Telefones',
            'Todos os Emails',
            'Data de Abertura',
            'Situação CNPJ',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Funcionários da Unidade CNPJ',
            'Endereço na Web',
            'Administrador',
            'Decisores',
            'Emails Principais',
            'Emails Válidos',
            'Emails Incertos',
            'Telefones Principais',
        ],
        piperun: [
            'Razão Social/Nome Fantasia',
            'CNPJ',
            'Endereço',
            'Situação',
            'Dados Corporativos',
            'Nome do Administrador',
            'Capital Social',
            'Setor',
            'Cnae Primário',
            'Cnae Secundário',
            'Data de Abertura',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Faturamento da Empresa',
            'Faixa de Funcionários da Unidade CNPJ',
            'Faixa de Funcionarios da Empresa',
            'Natureza Jurídica',
            'Administrador',
            'Facebook',
            'Website',
            'Todos os Emails',
            'Todos os Telefones',
            'Telefones Válidos',
            'Decisores',
            'Emails Principais',
            'Telefones Principais',
            'Origem',
        ],
        rdstation: [
            'Razão Social',
            'CNPJ',
            'Nome Fantasia',
            'Natureza Jurídica',
            'Setor',
            'Website',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Funcionários da Unidade CNPJ',
            'Data de Abertura',
            'Situação do CNPJ',
            'Endereço',
            'Todos os Telefones',
            'Todos os Emails',
            'Nome do Administrador',
            'Decisores',
            'Emails Principais',
            'Telefones Principais',
        ],
        salesforce: [
            'Razão Social/Nome Fantasia',
            'CNPJ',
            'Setor',
            'Endereço',
            'Cidade',
            'Estado',
            'País',
            'Telefones',
            'Emails',
            'Website',
            'CNAE Primário',
            'CNAEs Secundários',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Funcionários da Unidade CNPJ',
            'Quadro Societário',
            'Emails Principais',
            'Telefones Principais',
        ],
        totvs: [
            'Nome/Razão Social',
            'Apelido/Nome Fantasia',
            'CNPJ',
            'Endereço',
            'Data de Nascimento/Data de Fundação',
            'Todos os Emails',
            'Todos os Telefones',
            'Facebook',
            'Website',
            'Outras Informações/Observações',
        ],
        funildevendas: [
            'Razão Social',
            'CNPJ',
            'Endereço',
            'Setor',
            'Decisores',
            'Outras Informações',
        ],
        zoho: [
            'Razão Social/Nome Fantasia',
            'CNPJ',
            'Setor',
            'Endereço',
            'Cnae Primário',
            'Cnae Secundário',
            'Data de Abertura',
            'Faixa de Faturamento da Unidade CNPJ',
            'Faixa de Faturamento da Empresa',
            'Faixa de Funcionários da Unidade CNPJ',
            'Faixa de Funcionarios da Empresa',
            'Natureza Jurídica',
            'Administrador',
            'Facebook',
            'Website',
            'Todos os Emails',
            'Todos os Telefones',
            'Decisores',
            'Situação',
            'Nome do Administrador',
            'Capital Social',
            'Emails Principais',
            'Telefones Principais',
        ],
    },
};
export function getfieldFilters(index) {
    return fieldFilters[index] || fieldFilters['CRM'][index];
}
const videosCRM = {
    salesforce: 'https://www.loom.com/share/4a1c62e472b9493aaf9c4449f4f91ca7',
    rdstation: 'https://www.loom.com/share/35a626c7d4b94637aa60edc1e7015c24?sid=c77b787c-b7eb-40fe-b885-1e8ca408f734',
    pipedrive: 'https://www.loom.com/share/9e0c72911a69452bb0b0a708ad241b5f?sid=3b45e133-deee-4542-9ade-8376965f08ba',
    ploomes: 'https://www.loom.com/share/a25c4af065a6483f9d2736fe91dec30f?sid=6f5680a5-47d3-430e-bdfb-0ba6d2f8031e',
    hubspot: 'https://www.loom.com/share/378d9bad45ac489bb5e88ca9ef02fa3a?sid=e12c78c7-f03a-4e97-a5dd-3a5d48454715',
    agendor: 'https://www.loom.com/share/51e08a1a682a46d8b864d4236aedbebf?sid=3aa2875f-d92e-4c05-a02d-3818e4ca6d1d',
    bitrix24: 'https://www.loom.com/share/0c82c66b59c54298a146209984d8cfaf?sid=7b28b243-6754-43cc-aad1-436e216c041e',
    funildevendas: 'https://www.loom.com/share/522337bd25b04a04bd55c3e23cdbe43e?sid=6de626db-4233-4712-891e-55c79e185303',
    totvs: 'https://share.vidyard.com/watch/r6F6JpMi9o82b2CrgL7iKy',
    zoho: 'https://share.vidyard.com/watch/YDFq8SumNj2BwiDiuAJwmU',
    piperun: 'https://www.loom.com/share/b9e18861ae604cbfb72daeaec7891a4c?sid=26dfae47-58df-4489-a1bd-ec16173703c6',
    meetime: 'https://www.loom.com/share/ca0e01677473402d95428db07ba4bc0c?sid=67cdfe89-6ac5-492d-bad9-aec08e946694',
};
export function getVideosCRM(index) {
    return videosCRM[index];
}
