var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import { connect } from '@/overmind';
let ValidateForm = class ValidateForm extends Vue {
    constructor() {
        super(...arguments);
        this.token = new Array(6).fill('');
        this.errorField = false;
        this.disable = false;
        this.sendingCode = false;
    }
    goBack(changed) {
        this.$emit('goBack', changed);
    }
    onPaste(e) {
        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();
        // @ts-ignore Get pasted data via clipboard API
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        const numberPastedData = pastedData.replace(/\D/g, '');
        if (numberPastedData.length <= 6) {
            const pastedCode = Array.from(numberPastedData, num => Number(num));
            const emptyElements = new Array(6 - pastedCode.length).fill('');
            const code = pastedCode.concat(emptyElements);
            this.token = code;
            this.updateFocus(pastedCode.length, '-');
        }
    }
    updateFocus(index, operator) {
        const focus = eval(index + operator + 1);
        if (focus > this.token.length - 1 || focus < 0)
            return;
        const ref = this.$refs[`validateFormInput${focus}`];
        ref[0].focus();
    }
    deleteCurrent(index) {
        const currentInput = this.$refs[`validateFormInput${index}`];
        currentInput[0].value = '';
        this.token[index] = '';
    }
    handleKeydown(e, index) {
        if (['-', 'e', '+', 'E'].includes(e.key)) {
            e.preventDefault();
            return;
        }
        if (['Backspace'].includes(e.key)) {
            if (this.token[index] == '') {
                this.updateFocus(index, '-');
            }
            this.deleteCurrent(index);
        }
        if (['Delete'].includes(e.key)) {
            if (this.token[index] == '') {
                for (let each in this.token) {
                    if (+each > index) {
                        const input = this.$refs[`validateFormInput${each}`];
                        if (input[0].value !== '') {
                            input[0].value = '';
                            this.token[each] = '';
                            return;
                        }
                    }
                }
            }
            this.deleteCurrent(index);
        }
        if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            const arrow = e.key == 'ArrowLeft' ? '-' : '+';
            if (arrow == '+' && !this.token[index]) {
                return;
            }
            this.updateFocus(index, arrow);
        }
    }
    handleChange(e, index) {
        const val = e.data;
        if (e.target.value == '') {
            return;
        }
        this.token[index] = val;
        this.updateFocus(index, '+');
    }
    async handleToken(val) {
        for (let each of val) {
            if (each == '') {
                this.disable = true;
                return;
            }
        }
        if (this.btnLoading == true)
            return;
        this.disable = false;
        this.authentication();
    }
    authentication() {
        return this.token.join('');
    }
    async mounted() {
        await this.handleCodeResend();
    }
    async handleCodeResend() {
        if (['signin', 'signup'].includes(this.validationType)) {
            this.$emit('handleAlertMessage', 'Informe o token de 6 dígitos que enviamos para o seu e-mail');
        }
        else {
            this.$emit('handleAlertMessage', 'Insira o token enviado para seu whatsapp e continue seu período de teste gratuito na Speedio.');
        }
        await this.codeResend();
    }
    async codeResend() {
        this.sendingCode = true;
        let result;
        if (['signin', 'signup'].includes(this.validationType)) {
            //@ts-ignore
            result = await this.users.tryCreateAuthToken({
                type: this.validationType,
                ...this.data,
            });
        }
        else {
            //@ts-ignore
            result = await this.users.sendWhatsappConfirmationUserJorney({
                whatsapp_number: this.validateNumber,
            });
        }
        this.sendingCode = false;
        if (!result || (result && result.status == 422)) {
            this.$emit('errorOnSendCode', true);
        }
    }
};
__decorate([
    Prop({ default: '' })
], ValidateForm.prototype, "data", void 0);
__decorate([
    Prop({ default: () => ({}) })
], ValidateForm.prototype, "validateNumber", void 0);
__decorate([
    Prop({ default: 'signin' })
], ValidateForm.prototype, "validationType", void 0);
__decorate([
    Prop({ default: '' })
], ValidateForm.prototype, "alertMessage", void 0);
__decorate([
    Prop({ default: false })
], ValidateForm.prototype, "btnLoading", void 0);
__decorate([
    Prop({ default: false })
], ValidateForm.prototype, "hideBtns", void 0);
__decorate([
    Prop({ default: false })
], ValidateForm.prototype, "changeTokenPhrase", void 0);
__decorate([
    Watch('token')
], ValidateForm.prototype, "handleToken", null);
__decorate([
    Emit('authentication')
], ValidateForm.prototype, "authentication", null);
ValidateForm = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        components: {
            IconSpeedioSpinner,
        },
    }))
], ValidateForm);
export default ValidateForm;
